<template>
  <div class="web">
    <div class="item" v-for="item in list" :key="item.id">
      <div class="right">
        <div class="time">
          <span class="font16 fontw">报名时间</span>
          <span class="font14" style="margin-top: 5px; text-align: center; word-break: break-all">
            {{ item.enrollStartTime.slice(0, -3) }} -
            {{ item.enrollEndTime.slice(0, -3) }}
          </span>
        </div>
        <div class="content font16 color33">
          <div class="text">
            <p>
              <span class="fontw" style="width: 50px">职业:</span>
              <span style="flex: 1">
                {{ item.skillCategoryFirstName }}({{
                item.skillCategorySecondName
                }})
              </span>
            </p>
            <p>
              <span class="fontw">计划号:</span>
              <span class="margin10">{{ item.planNo }}</span>
            </p>
          </div>
          <div class="text">
            <p>
              <span class="fontw">级别:</span>
              <span class="margin10">{{ item.skillCategoryThirdName }}</span>
            </p>
            <p>
              <span class="fontw">认定地点:</span>
              <el-popover placement="bottom" width="150" trigger="hover">
                <span class="margin10">
                  {{ item.provinceName }}{{ item.cityName
                  }}
                  <span v-for="(item_c, index) in item.areaName" :key="index">
                    {{ item_c
                    }}{{ index + 1 != item.areaName.length ? "," : "" }}
                  </span>
                </span>
                <span class="margin10 place_point" slot="reference">
                  {{ item.provinceName }}{{ item.cityName
                  }}
                  <span v-for="(item_c, index) in item.areaName" :key="index">
                    {{ item_c
                    }}{{ index + 1 != item.areaName.length ? "," : "" }}
                  </span>
                </span>
              </el-popover>
            </p>
          </div>
          <div class="text">
            <p>
              <span class="fontw">状态:</span>
              <span v-show="item.status == 0 && item.alreadyIn == 1" class="margin10 success">已报名</span>
              <span v-show="item.status == 0 && !item.alreadyIn" class="margin10 skyblue">报名中</span>
              <span v-show="item.status != 0" class="margin10 danger">已截止</span>
              <!-- <span class="margin10 skyblue">报名中</span> -->
            </p>
            <p>
              <span class="fontw">报名费用:</span>
              <span class="margin10 price">
                <i class="font12">￥</i>
                {{ item.examFee }}元
              </span>
            </p>
          </div>
        </div>
      </div>
      <p class="color33 planstarttime" v-if="item.startTime">
        <span class="font14 status">认定时间：</span>
        <span class="font14">
          {{ item.startTime.split(" ")[0].replace(/-/g, ".") }}-{{
          item.endTime.split(" ")[0].replace(/-/g, ".")
          }}
        </span>
      </p>
      <p
        v-if="item.status == 0 && !item.alreadyIn"
        class="btn font14 colorff"
        @click="toSubPartner(item)"
      >去报名</p>
      <p class="btn font14 colorff" v-else style="opacity: 0">去报名</p>
    </div>
    <el-dialog title="确定个人信息" :visible.sync="showUserInfo" width="600px" center>
      <div class="info_box">
        <div class="info_top">
          <div class="header">
            <img
              style="width: 100%; height: 100%"
              :src="
                userInfo.picUrl
                  ? userInfo.picUrl
                  : require('../assets/normal_header.jpg')
              "
              alt
            />
          </div>
          <span>{{ userInfo.name }}</span>
        </div>
        <div class="info_text">
          <li>
            <span>手机号：</span>
            <span>{{ userInfo.mobile }}</span>
          </li>
          <li>
            <span>身份证：</span>
            <span>{{ userInfo.idCard }}</span>
          </li>
        </div>
        <el-button type="primary" @click="toSignUp" block class="my_btn">确 定 报 名</el-button>
      </div>
    </el-dialog>
    <el-dialog title="所属单位" :visible.sync="showLartner" width="30%">
      <el-form :model="form">
        <el-form-item label="所属单位" label-width="100">
          <el-select v-model="form.partner" placeholder="请选择所属单位" style="100%" filterable>
            <el-option
              :label="item.partnerName"
              :value="item.id"
              v-for="item in this.partnerList"
              :key="item.id"
            ></el-option>
            <el-option label="本单位" value="null"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showLartner = false">取 消</el-button>
        <el-button type="primary" @click="toSign">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { getUserInfo, signUp, getCertStatus, getPartnerList } from "@/util/api";
export default {
  props: {
    list: {
      type: Array
    }
  },
  data() {
    return {
      showUserInfo: false,
      userInfo: {},
      form: {},
      changePlanNo: null,
      showLartner: false,
      partnerList: []
    };
  },
  mounted() {
    this.toGetUserInfo();
    // console.log(this.list);
  },

  methods: {
    ...mapMutations(["changeShowInfo"]),
    toGetUserInfo() {
      getUserInfo()
        .then(res => {
          this.userInfo = res.data;
        })
        .catch();
    },

    // 提交报名
    toSignUp() {
      this.showLartner = false;
      console.log(this.form.partner,'euueueuueu');
      if (this.form.partner == 'null') {
        signUp({
          planId: this.changePlanNo.id
        })
          .then(res => {
            this.$message.success("提交报名信息成功，等待工作人员审核");
            this.showLartner = false;
            this.showUserInfo = false;
            this.$emit("getDataList");
          })
          .catch(() => {
            this.$emit("getDataList");
            this.showUserInfo = false;
          });
      } else {
        signUp({
          planId: this.changePlanNo.id,
          partnerId: this.form.partner
        })
          .then(res => {
            this.$message.success("提交报名信息成功，等待工作人员审核");
            this.showLartner = false;
            this.showUserInfo = false;
            this.$emit("getDataList");
          })
          .catch(() => {
            this.$emit("getDataList");
            this.showUserInfo = false;
          });
      }
    },
    toSign() {
      window.sessionStorage.removeItem("myObj");
      this.showLartner = false;
      if (!this.form.partner && this.changePlanNo.isOpenPartner == 1) {
        this.$message.warning("请选择机构名称");
        return;
      }
      if (
        this.changePlanNo.examFee == 0 &&
        this.changePlanNo.isApplyFull == 0
      ) {
        // 免费且不用表格
        this.toGetUserInfo();
        this.showUserInfo = true;
      } else if (
        this.changePlanNo.examFee != 0 &&
        this.changePlanNo.isApplyFull == 0
      ) {
        // this.form.partner = "";
        // 不免费且不用表格
        this.$router.push({
          path: "/process",
          query: {
            planId: this.changePlanNo.id,
            isNotFree: 3,
            partnerId: this.form.partner
          }
        });
      } else {
        // this.form.partner = "";
        // 填写表格
        this.$router.push({
          path: "/process",
          query: {
            planId: this.changePlanNo.id,
            isNotFree: this.changePlanNo.examFee == 0 ? 1 : 2,
            partnerId: this.form.partner
          }
        });
      }
    },
    
    toSubPartner(item) {
      console.log(this.$store.state.userId,'1报名')
      let uid = localStorage.getItem('uid')
      console.log(uid,'uid')
      // if(this.$store.state.userId!==uid){
      //   console.log('账号不一致')
      //   location.reload();
      // }
      var str = this.userInfo.idCard,
          idCard = '';
      if(this.userInfo.idCard.indexOf("X") != -1){
        idCard = JSON.parse(str.slice(0, -1))
      }else{
        idCard = JSON.parse(str)
      }
      var myDate = new Date();
		  var month = myDate.getMonth() + 1;
		  var day = myDate.getDate()
		  var age = myDate.getFullYear() - (idCard + '' ).substring(6, 10) - 1
		  if ((idCard + '' ).substring(10, 12) < month || (idCard + '' ).substring(10, 12) == month && (idCard + '' ).substring(12, 14) <= day) {
			  age++
		  }
		  if(age < 16){
        this.$message.warning("未满16岁不能报名");
			  return
		  }
      getUserInfo().then(res => {
        this.userInfo = res.data;
        // window.localStorage.setItem("companyId", res.data.companyId);
        if (res.data.isFull != 1) {
          this.changeShowInfo(true);
        } else {
          getCertStatus({
            planId: item.id
          })
            .then(res => {
              if (item.isOpenPartner == 1) {
                this.showLartner = true;
                this.changePlanNo = item;
                // this.toSign(item);
              } else {
                this.changePlanNo = item;
                this.toSign();
              }
            })
           .catch();
        }
      });

      if (item.isOpenPartner == "1") {
        getPartnerList({ planId: item.id }).then(res => {
          // console.log(res,'wwwwwwww');
          this.partnerList = res.data;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.web {
  padding: 0 30px;
  .item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #fff;
    border-radius: 10px;
    border-bottom: 1px dashed #cccccc;
    .right {
      display: flex;
      align-items: center;
      flex: 1;
      .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 0 60px;
        .text {
          display: flex;
          margin-bottom: 10px;
          align-items: flex-start;

          p {
            display: flex;
            align-items: flex-start;
            flex: 1;
            .margin10 {
              margin-left: 10px;
              flex: 1;
            }
            .place_point {
              width: 175px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              display: inline-block;
              cursor: pointer;
            }
            .price {
              color: #ff5b5b;
              i {
                font-style: normal;
              }
            }
          }
        }
      }
    }
    .time {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 290px;
      height: 65px;
      background: rgba(255, 255, 255, 0.39);
      border: 1px solid #cccccc;
      border-radius: 10px;
      color: #966161;
    }
    .btn {
      width: 102px;
      height: 32px;
      background: #2b68d9;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 19px;
      cursor: pointer;
    }
  }

  .item:last-of-type {
    border-bottom: none;
  }
}
.info_box {
  width: 80%;
  margin: 0 auto;
  .info_top {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    .header {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #eeeeee;
    }
    span {
      margin-top: 10px;
      font-weight: bold;
      font-size: 14px;
    }
  }
  .info_text {
    display: flex;
    width: 100%;
    margin: 30px auto;
    align-items: center;
    li {
      width: 50%;
      list-style: none;
      span:first-of-type {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      span:last-of-type {
        margin-left: 5px;
        font-size: 14px;
        color: #515a6e;
      }
    }
  }
  .my_btn {
    margin: 20px auto 10px;
    display: flex;
  }
}
.planstarttime {
  position: absolute;
  bottom: 15px;
  right: 20px;
}
</style>
